// extracted by mini-css-extract-plugin
export var count = "search-results-module--count--074c6";
export var header = "search-results-module--header--aa083";
export var heading = "search-results-module--heading--815d1";
export var loading = "search-results-module--loading--09db7";
export var next = "search-results-module--next--76630";
export var pagination = "search-results-module--pagination--c997a";
export var previous = "search-results-module--previous--3da78";
export var query = "search-results-module--query--c863d";
export var result = "search-results-module--result--8d2c4";
export var results = "search-results-module--results--5ccdf";
export var spellingLink = "search-results-module--spellingLink--407ec";
export var wrapper = "search-results-module--wrapper--dbf7b";